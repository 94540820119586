import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/coupon/kfc'


const getDataApi =(params)=>postAction(baseURL+"/getData",params);


export{
    getDataApi,
}

<template>
  <div>
    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col
            :md="6"
            :sm="8"
            v-for="(value, code) in roleList"
            v-bind:key="code"
          >
            <el-button
              v-if="value.seniorStatus != '2'"
              :disabled="value.seniorStatus == '3'"
              placeholder
              style="width: 60%"
              v-model="queryParam.fundName"
              @click="getCouponInfoButton(value)"
              >{{ value.name }}</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div style="margin: 100px">
      <el-link
        :disabled="this.clickUrl1 == null"
        :href="this.clickUrl1"
        target="_blank"
        style="font-family: arial; font-size: 30px; text-align: center"
        >链接1--点击跳转</el-link
      >
      <br />
      <br />
      <el-link
        :disabled="this.clickUrl2 == null"
        :href="this.clickUrl2"
        target="_blank"
        style="font-family: arial; font-size: 30px; text-align: center"
        >链接2--点击跳转</el-link
      >
    </div>
  </div>
</template>
<script>
import { getCouponListApi } from "@/api/system/mune/menuCoupon.js";
import { getDataApi } from "@/api/coupon/wenyuApi.js";
export default {
  props: ["homeMenus"],
  data() {
    return {
      queryParam: {},
      roleList: [],
      clickUrl1: null,
      clickUrl2: null,
      clickUrl3: null,
      clickUrl4: null,
    };
  },
  mounted() {
    this.getMuneList();
  },
  //防止第一次失效
  created() {
    this.getMuneList();
  },
  methods: {
    getMuneList() {
      console.log(this.homeMenus);
      var query = {
        type: 3,
        resources: this.homeMenus.code,
        productSource: this.homeMenus.productSource,
      };
      getCouponListApi(query).then((res) => {
        this.roleList = res.result;
      });
    },
    clearUrl() {
      this.clickUrl1 = null;
      this.clickUrl2 = null;
      this.clickUrl3 = null;
    },
    getCouponInfoButton(val) {
      this.clearUrl();
      var query = {
        menuCode: val.code,
        activityId: val.typeParam,
        dataSign: val.dataSign,
      };
      getDataApi(query).then((res) => {
        if (val.dataSign == "cinema") {
          this.clickUrl1 = res.result.cinema_api.data.url;
          this.clickUrl2 = res.result.cinema_api.data.h5_url;
        }
        else if (val.dataSign == "kfc") {
          this.clickUrl1 = res.result.kfc_api.data.url;
          this.clickUrl2 = res.result.kfc_api.data.h5_url;
        }
        else if (val.dataSign == "wenyu") {
          this.clickUrl1 = res.result.wenyu_api.data.url;
          this.clickUrl2 = res.result.wenyu_api.data.h5_url;
        }
        else if (val.dataSign == "jiayou") {
          this.clickUrl1 = res.result.jiayou_api.data.url;
          this.clickUrl2 = res.result.jiayou_api.data.h5_url;
        }
      });
    },
  },
};
</script>
<style scoped>
</style>